.preloader {
	position: fixed;
	margin: auto;
	display: block;
	width: 150px;
	height: 150px;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	@media(min-width: @medium){
		width: 200px;
		height: 200px;
	}

	img {
		.posc();
		width: 70px;
		height: 70px;
		@media(min-width: @medium){
			width: 100px;
			height: 100px;
		}
	}
}
.preloader-whirlpool,
.preloader-whirlpool::after {
	position: absolute;
	top: 50%;
	left: 50%;
	border: 3px solid rgb(127,142,148);
	border-left-color: rgb(255,208,0);
	border-radius: 2747px;
	box-sizing: content-box;
}
.preloader-whirlpool {
	margin: -69px 0 0 -69px;
	height: 138px;
	width: 138px;
	animation: preloader-rotate 1000ms linear infinite;
}
.preloader-whirlpool::after {
	content: "";
	margin: -80px 0 0 -80px;
	height: 154px;
	width: 154px;
	animation: preloader-rotate 2000ms linear infinite;
}
@keyframes preloader-rotate {
	100% {
		transform: rotate(360deg);
	}
}