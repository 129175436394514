.make-incase(@i: length(@rarity-colors)) when (@i > 0){
	.make-incase(@i - 1);
	@name: extract(@rarity-names, @i);
	@color: extract(@rarity-colors, @i);
	&_@{name}{
		background-image: url('../assets/img/item-frames/item_overlay_@{name}.svg');
	}
}

.item-incase{
	.column-acenter();
	position: relative;
	z-index: 5;
	width: 100%;
	height: 100%;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	transition: .15s ease;
	&__img{
		width: 60%;
		height: auto;
		max-height: 60%;
		margin-bottom: 2px;
		padding-top: 2px;
	}
	&__names{
		width: 100%;
		padding: 0 5px;
		font-size: inherit;
		position: relative;
		z-index: 2;
		text-align: left;
	}
	&__first-name, &__second-name{
		color: #fff;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.make-incase();
}