.roulette {
    display: flex;
	position: relative;
	z-index: 1;
	width: 100%;
	&:before, &:after{
		content: '';
		position: absolute;
		top: 50%;
		z-index: 2;
		background-size: 100% 100%;
		background-image: url('/assets/img/roulette/roulette-frame.svg');
		background-position: center;
		background-repeat: no-repeat;
		height: 100%;
		pointer-events: none;
		.max(@small, {
			background-image: none;
			background: linear-gradient(90deg, rgba(6,21,26,1) 0%, rgba(255,255,255,0) 100%);
			width: 50px !important;
			height: calc(100% - 10px);
		});
	}
	&:before{
		transform: translateY(-50%);
	}
	&:after{
		transform: translateY(-50%) scaleX(-1);
	}
	.max(@small, {
		margin: 10px auto;
		padding: 5px 0;
		height: 160px;
		&:before, &:after{
			width: 460px;
		}
		&:before{
			left: 0px;
		}
		&:after{
			right: 0px;
		}
	});
	.min(@small, {
		margin: 20px auto;
		padding: 5px 100px;
		height: 292px;
		&:before, &:after{
			width: 700px;
		}
		&:before{
			left: -2px;
		}
		&:after{
			right: -2px;
		}
	});
	&__frame{
		position: relative;
		z-index: 1;
		padding: 6px 2px;
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		overflow: hidden;
		background-color: @color-site;
		border-bottom: 2px solid #0b3539;
		border-top: 2px solid #0b3539;
		&:before, &:after {
			content: '';
			position: absolute;
			z-index: 3;
            background-image: url('/assets/img/roulette/roulette-arrow.svg');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center;
			left: 50%;
			transform: translateX(-50%);
			.max(@small, {
				width: 25px;
				height: 13px;
			});
			.min(@small, {
				width: 47px;
				height: 24px;
			});
		}

		&:before{
			top: 0;
		}

		&:after{
			bottom: 0;
			transform: translateX(-50%) rotate(180deg);
		}
	}

	&__items{
		display: flex;
		flex: none;
	}

	&__wrapper{
		height: 100%;
		width: 100%;
	}

	&__cursor{
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: ~'calc(100% - 30px)';
		height: 2px;
		background-color: @slate300;
		&:before, &:after{
			content: '';
			position: absolute;
			z-index: 1;
			left: 0;
			width: 100%;
			height: 180px;
		}
		&:before{
			top: -160px;
			background: linear-gradient(180deg, fade(@slate50, 0%) 60%, @slate50 90%);
			transform: rotate(180deg);
		}
		&:after{
			background: linear-gradient(180deg, fade(@slate50, 0%) 60%, @slate50 90%);
			bottom: -170px;
		}
	}
    &:not(&_vertical) &__items{
		height: 100%;
		margin-left: 50%;
		.max(@small, {
			transform: translateX(-210px);
		});
		.min(@small, {
			transform: translateX(-750px);
		});
		.roulette-item{
			.max(@small, {
				&__img{
					margin: auto;
				}
				// &__type, &__name{
				// 	display: none;
				// }
			});

			&.skin-win-highlight{
				z-index: 2;
				box-shadow: 0 0 0 1200px fade(@slate50, 50%);
			}
		}
	}
    &_vertical{
		.min(@small, {
			min-width: 840px;
		});
		&:before, &:after{
			z-index: 3;
		}
	}
	&_vertical &{
		&__frame{
			padding: 0;
			justify-content: center;
			align-items: initial;
			&:before, &:after{
				top: 0;
				bottom: 0;
				margin: auto 0;
				z-index: 4;
			}
			&:before{
				transform: rotate(270deg);
				left: 0;
			}
			&:after{
				left: initial;
				right: 0;
				transform: rotate(90deg);
			}
		}
		&__items{
			flex-direction: column;
			align-items: center;
			height: fit-content;
			.roulette-item{
				margin-top: 2px;
				margin-bottom: 2px;
				.max(@small, {
					width: 70px;
					height: 75px;
				});
				.only(@small, @medium, {
					width: 95px;
					height: 100px;
					margin-left: 2px;
					margin-right: 2px;
				});
				.max(@medium, {
					padding: 5px;
					min-height: auto;
					&__type, &__name{
						display: none;
					}
					&__img{
						width: 100%;
						min-height: 25px;
						margin: auto;
					}
				});
			}
		}
	}
}