.layout-roulette {
    width: 100%;
    padding: 20px;
    max-width: 100vw;
    overflow: hidden;
    .max(@small, {
        padding: 20px 0;
    });
    &__btn {
        display: flex;
        justify-content: center;
    }
}