.roulette-item {
    position: relative;
	display: flex;
	flex: none;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	background: radial-gradient(50% 50% at 50% 50%, #0b3539 0%, #065D55 100%);
	border: 2px solid #0b3539;
	border-bottom-color: var(--rarity-color);

	// It's sol fixed flickering bug
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transition: .3s ease;
	.max(@small, {
		width: 135px;
		margin: 0 2px;
		padding: 10px;
	});
	.min(@small, {
		width: 225px;
		margin: 0 5px;
		min-height: 250px;
		padding: 16px;
		text-align: center;
	});
	&__img{
		margin: 0 auto auto;
		.max(@small, {
			width: 90px;
		});
		.min(@small, {
			width: 184px;
		});
	}
	&__type, &__name{
		.text-overflow();
		font-weight: 500;
		line-height: 15px;
		text-align: left;
		.max(@small, {
			font-size: 12px;
			line-height: 13px;
		});
		.min(@small, {
			font-size: 14px;
		});
	}
	&__type{
		margin-bottom: 3px;
		margin-top: auto;
		color: white;
	}
	&__name{
		padding-right: 0;
		font-weight: 600;
		// color: #fff;
		color: #b6babc;
		.max(@small, {
			font-size: 10px;
		});
		.min(@small, {
			margin-bottom: 10px;
		});
	}

	&.skin-win-highlight{
		border-color: var(--rarity-color);
	}
	&_win {
		border-color: var(--rarity-color);
		animation: winItem 2s linear infinite alternate;
		transform: scale(1.01);
	}
}
@keyframes winItem {
	0% {
		box-shadow: 0px 0px 1px var(--rarity-color);
	}
	100% {
		box-shadow: 0px 0px 12px var(--rarity-color);
	}
}