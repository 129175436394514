.error {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	&__icon {
		width: 100px;
		height: 100px;
		margin-bottom: 30px;
	}
	&__title {
		font-size: 19px;
		color: white;
	}
}