* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
textarea,
button,
input {
  padding: 0;
  margin: 0;
  box-shadow: none;
  outline: none;
  border-radius: 0;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-family: inherit;
}
button {
  background-color: transparent;
  color: currentColor;
}
table {
  border-collapse: collapse;
}
button {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
a,
p {
  word-wrap: break-word;
}
.accent {
  --rarity-color: #13CDFF;
  --state-color: #13CDFF;
}
.error {
  --rarity-color: #FF3030;
  --state-color: #FF3030;
}
.success {
  --rarity-color: #82FF1B;
  --state-color: #82FF1B;
}
.default {
  --rarity-color: #41496B;
  --rarity-color-0: rgba(65, 73, 107, 0);
  --rarity-color-10: rgba(65, 73, 107, 0.1);
  --rarity-color-35: rgba(65, 73, 107, 0.35);
  --rarity-color-70: rgba(65, 73, 107, 0.7);
  --rarity-color-90: rgba(65, 73, 107, 0.9);
}
.uncommon {
  --rarity-color: #009ae2;
  --rarity-color-lighten: #9FAEC5;
  --rarity-color-0: rgba(0, 154, 226, 0);
  --rarity-color-10: rgba(0, 154, 226, 0.1);
  --rarity-color-35: rgba(0, 154, 226, 0.35);
  --rarity-color-70: rgba(0, 154, 226, 0.7);
  --rarity-color-90: rgba(0, 154, 226, 0.9);
}
.common {
  --rarity-color: #89c0db;
  --rarity-color-lighten: #9FAEC5;
  --rarity-color-0: rgba(137, 192, 219, 0);
  --rarity-color-10: rgba(137, 192, 219, 0.1);
  --rarity-color-35: rgba(137, 192, 219, 0.35);
  --rarity-color-70: rgba(137, 192, 219, 0.7);
  --rarity-color-90: rgba(137, 192, 219, 0.9);
}
.milspec {
  --rarity-color: #1E78FF;
  --rarity-color-lighten: #9FAEC5;
  --rarity-color-0: rgba(30, 120, 255, 0);
  --rarity-color-10: rgba(30, 120, 255, 0.1);
  --rarity-color-35: rgba(30, 120, 255, 0.35);
  --rarity-color-70: rgba(30, 120, 255, 0.7);
  --rarity-color-90: rgba(30, 120, 255, 0.9);
}
.restricted {
  --rarity-color: #8F00FF;
  --rarity-color-lighten: #B59BCD;
  --rarity-color-0: rgba(143, 0, 255, 0);
  --rarity-color-10: rgba(143, 0, 255, 0.1);
  --rarity-color-35: rgba(143, 0, 255, 0.35);
  --rarity-color-70: rgba(143, 0, 255, 0.7);
  --rarity-color-90: rgba(143, 0, 255, 0.9);
}
.classified {
  --rarity-color: #FF12AE;
  --rarity-color-lighten: #BD9FB7;
  --rarity-color-0: rgba(255, 18, 174, 0);
  --rarity-color-10: rgba(255, 18, 174, 0.1);
  --rarity-color-35: rgba(255, 18, 174, 0.35);
  --rarity-color-70: rgba(255, 18, 174, 0.7);
  --rarity-color-90: rgba(255, 18, 174, 0.9);
}
.covert {
  --rarity-color: #FF1212;
  --rarity-color-lighten: #BF9EA2;
  --rarity-color-0: rgba(255, 18, 18, 0);
  --rarity-color-10: rgba(255, 18, 18, 0.1);
  --rarity-color-35: rgba(255, 18, 18, 0.35);
  --rarity-color-70: rgba(255, 18, 18, 0.7);
  --rarity-color-90: rgba(255, 18, 18, 0.9);
}
.rare {
  --rarity-color: #FFC701;
  --rarity-color-lighten: #BFB8A0;
  --rarity-color-0: rgba(255, 199, 1, 0);
  --rarity-color-10: rgba(255, 199, 1, 0.1);
  --rarity-color-35: rgba(255, 199, 1, 0.35);
  --rarity-color-70: rgba(255, 199, 1, 0.7);
  --rarity-color-90: rgba(255, 199, 1, 0.9);
}
body {
  max-width: 100vw;
  background-color: #06151A;
  background-image: url(/assets/img/background.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  font-family: Roboto Slab, Ecoin;
}
@font-face {
  font-family: 'Ecoin';
  src: url('/assets/fonts/ecoin.ttf');
  font-weight: normal;
  font-style: normal;
}
.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15px 20px;
  background-color: #FBCA02;
  border-radius: 5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #07111A;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.2s ease;
  text-align: center;
  border: 2px solid transparent;
}
@media (min-width: 630px) {
  .button {
    padding: 15px 40px;
    font-size: 19px;
  }
}
.button__value {
  display: flex;
}
.button:hover {
  background-color: #F3E603;
}
.button_refill {
  padding: 10px 25px;
  font-size: 30px;
  line-height: 35px;
}
.button_refill span:last-child {
  margin-top: 5px;
  font-size: 10px;
  line-height: 13px;
}
.button_make-contract {
  font-size: 15px;
}
.button_make-contract span:last-child {
  font-size: 13px;
}
.button_main {
  padding: 10px 30px;
  font-size: 18px;
}
.button_preload {
  padding: 10px 50px;
  min-height: 45px;
  width: auto;
}
@media (min-width: 630px) {
  .button_preload {
    min-height: 60px;
  }
}
.button_compact {
  padding: 15px 20px;
}
.button_red {
  background-color: #D0021B;
  color: #fff;
}
.button_red:hover {
  background-color: #f30220;
}
.button_lowercase {
  text-transform: none;
  padding: 15px 25px;
  font-size: 14px;
  line-height: 18px;
}
.button:disabled {
  background-color: rgba(214, 202, 156, 0.9);
  filter: grayscale(1);
  cursor: default;
}
.button_ghost {
  border: 2px solid #fbca02;
  background-color: rgba(251, 202, 2, 0.25);
  color: #fff;
}
.button_ghost:hover {
  background-color: rgba(251, 202, 2, 0.5);
}
.button_transparent {
  background: transparent;
}
.button_transparent.button_ghost {
  color: #FBCA02;
}
.button_with-value {
  flex-direction: row;
  justify-content: space-between;
}
.button_with-value.button_ghost .button__label {
  color: #F5B904;
}
.button_super-small {
  font-size: 12px;
  padding: 7px 13px;
}
.button_small {
  padding: 16px 19px;
  font-size: 14px;
}
.button_mobile-square {
  border-radius: 0;
}
@media (min-width: 630px) {
  .button_mobile-square {
    border-radius: 5px;
  }
}
.button_rounded {
  border-radius: 55px;
}
.button_border {
  background-color: transparent;
  color: #FBCA02;
  border: 1px solid rgba(251, 202, 2, 0.3);
  padding: 17px 20px;
  font-size: 14px;
  line-height: 16px;
  width: fit-content;
}
.button_border:hover {
  color: #07111A;
}
.button_border:disabled {
  opacity: 0.3;
  pointer-events: none;
}
.button_fit {
  width: fit-content;
}
.main-page {
  height: 100vh;
}
.main-page__header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
}
.layout-roulette {
  width: 100%;
  padding: 20px;
  max-width: 100vw;
  overflow: hidden;
}
@media not screen and (min-width: 630px) {
  .layout-roulette {
    padding: 20px 0;
  }
}
.layout-roulette__btn {
  display: flex;
  justify-content: center;
}
.roulette {
  display: flex;
  position: relative;
  z-index: 1;
  width: 100%;
}
.roulette:before,
.roulette:after {
  content: '';
  position: absolute;
  top: 50%;
  z-index: 2;
  background-size: 100% 100%;
  background-image: url('/assets/img/roulette/roulette-frame.svg');
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  pointer-events: none;
}
@media not screen and (min-width: 630px) {
  .roulette:before,
  .roulette:after {
    background-image: none;
    background: linear-gradient(90deg, #06151a 0%, rgba(255, 255, 255, 0) 100%);
    width: 50px !important;
    height: calc(100% - 10px);
  }
}
.roulette:before {
  transform: translateY(-50%);
}
.roulette:after {
  transform: translateY(-50%) scaleX(-1);
}
@media not screen and (min-width: 630px) {
  .roulette {
    margin: 10px auto;
    padding: 5px 0;
    height: 160px;
  }
  .roulette:before,
  .roulette:after {
    width: 460px;
  }
  .roulette:before {
    left: 0px;
  }
  .roulette:after {
    right: 0px;
  }
}
@media only screen and (min-width: 630px) {
  .roulette {
    margin: 20px auto;
    padding: 5px 100px;
    height: 292px;
  }
  .roulette:before,
  .roulette:after {
    width: 700px;
  }
  .roulette:before {
    left: -2px;
  }
  .roulette:after {
    right: -2px;
  }
}
.roulette__frame {
  position: relative;
  z-index: 1;
  padding: 6px 2px;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #06151A;
  border-bottom: 2px solid #0b3539;
  border-top: 2px solid #0b3539;
}
.roulette__frame:before,
.roulette__frame:after {
  content: '';
  position: absolute;
  z-index: 3;
  background-image: url('/assets/img/roulette/roulette-arrow.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  left: 50%;
  transform: translateX(-50%);
}
@media not screen and (min-width: 630px) {
  .roulette__frame:before,
  .roulette__frame:after {
    width: 25px;
    height: 13px;
  }
}
@media only screen and (min-width: 630px) {
  .roulette__frame:before,
  .roulette__frame:after {
    width: 47px;
    height: 24px;
  }
}
.roulette__frame:before {
  top: 0;
}
.roulette__frame:after {
  bottom: 0;
  transform: translateX(-50%) rotate(180deg);
}
.roulette__items {
  display: flex;
  flex: none;
}
.roulette__wrapper {
  height: 100%;
  width: 100%;
}
.roulette__cursor {
  position: absolute;
  z-index: 2;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 30px);
  height: 2px;
  background-color: #767B9F;
}
.roulette__cursor:before,
.roulette__cursor:after {
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  width: 100%;
  height: 180px;
}
.roulette__cursor:before {
  top: -160px;
  background: linear-gradient(180deg, rgba(7, 10, 24, 0) 60%, #070A18 90%);
  transform: rotate(180deg);
}
.roulette__cursor:after {
  background: linear-gradient(180deg, rgba(7, 10, 24, 0) 60%, #070A18 90%);
  bottom: -170px;
}
.roulette:not(.roulette_vertical) .roulette__items {
  height: 100%;
  margin-left: 50%;
}
@media not screen and (min-width: 630px) {
  .roulette:not(.roulette_vertical) .roulette__items {
    transform: translateX(-210px);
  }
}
@media only screen and (min-width: 630px) {
  .roulette:not(.roulette_vertical) .roulette__items {
    transform: translateX(-750px);
  }
}
@media not screen and (min-width: 630px) {
  .roulette:not(.roulette_vertical) .roulette__items .roulette-item__img {
    margin: auto;
  }
}
.roulette:not(.roulette_vertical) .roulette__items .roulette-item.skin-win-highlight {
  z-index: 2;
  box-shadow: 0 0 0 1200px rgba(7, 10, 24, 0.5);
}
@media only screen and (min-width: 630px) {
  .roulette_vertical {
    min-width: 840px;
  }
}
.roulette_vertical:before,
.roulette_vertical:after {
  z-index: 3;
}
.roulette_vertical .roulette__frame {
  padding: 0;
  justify-content: center;
  align-items: initial;
}
.roulette_vertical .roulette__frame:before,
.roulette_vertical .roulette__frame:after {
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 4;
}
.roulette_vertical .roulette__frame:before {
  transform: rotate(270deg);
  left: 0;
}
.roulette_vertical .roulette__frame:after {
  left: initial;
  right: 0;
  transform: rotate(90deg);
}
.roulette_vertical .roulette__items {
  flex-direction: column;
  align-items: center;
  height: fit-content;
}
.roulette_vertical .roulette__items .roulette-item {
  margin-top: 2px;
  margin-bottom: 2px;
}
@media not screen and (min-width: 630px) {
  .roulette_vertical .roulette__items .roulette-item {
    width: 70px;
    height: 75px;
  }
}
@media only screen and (min-width: 630px) and (max-width: 1099px) {
  .roulette_vertical .roulette__items .roulette-item {
    width: 95px;
    height: 100px;
    margin-left: 2px;
    margin-right: 2px;
  }
}
@media not screen and (min-width: 1100px) {
  .roulette_vertical .roulette__items .roulette-item {
    padding: 5px;
    min-height: auto;
  }
  .roulette_vertical .roulette__items .roulette-item__type,
  .roulette_vertical .roulette__items .roulette-item__name {
    display: none;
  }
  .roulette_vertical .roulette__items .roulette-item__img {
    width: 100%;
    min-height: 25px;
    margin: auto;
  }
}
.roulette-item {
  position: relative;
  display: flex;
  flex: none;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  background: radial-gradient(50% 50% at 50% 50%, #0b3539 0%, #065D55 100%);
  border: 2px solid #0b3539;
  border-bottom-color: var(--rarity-color);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: 0.3s ease;
}
@media not screen and (min-width: 630px) {
  .roulette-item {
    width: 135px;
    margin: 0 2px;
    padding: 10px;
  }
}
@media only screen and (min-width: 630px) {
  .roulette-item {
    width: 225px;
    margin: 0 5px;
    min-height: 250px;
    padding: 16px;
    text-align: center;
  }
}
.roulette-item__img {
  margin: 0 auto auto;
}
@media not screen and (min-width: 630px) {
  .roulette-item__img {
    width: 90px;
  }
}
@media only screen and (min-width: 630px) {
  .roulette-item__img {
    width: 184px;
  }
}
.roulette-item__type,
.roulette-item__name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  line-height: 15px;
  text-align: left;
}
@media not screen and (min-width: 630px) {
  .roulette-item__type,
  .roulette-item__name {
    font-size: 12px;
    line-height: 13px;
  }
}
@media only screen and (min-width: 630px) {
  .roulette-item__type,
  .roulette-item__name {
    font-size: 14px;
  }
}
.roulette-item__type {
  margin-bottom: 3px;
  margin-top: auto;
  color: white;
}
.roulette-item__name {
  padding-right: 0;
  font-weight: 600;
  color: #b6babc;
}
@media not screen and (min-width: 630px) {
  .roulette-item__name {
    font-size: 10px;
  }
}
@media only screen and (min-width: 630px) {
  .roulette-item__name {
    margin-bottom: 10px;
  }
}
.roulette-item.skin-win-highlight {
  border-color: var(--rarity-color);
}
.roulette-item_win {
  border-color: var(--rarity-color);
  animation: winItem 2s linear infinite alternate;
  transform: scale(1.01);
}
@keyframes winItem {
  0% {
    box-shadow: 0px 0px 1px var(--rarity-color);
  }
  100% {
    box-shadow: 0px 0px 12px var(--rarity-color);
  }
}
.layout-roulettes {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.layout-roulettes_three .layout-roulette {
  padding: 0;
}
.layout-roulettes_more .layout-roulette {
  padding: 0;
}
.layout-roulettes_more .roulette {
  -webkit-transform: translate3d(0, 0, 0);
}
@media not screen and (min-width: 630px) {
  .layout-roulettes_more .roulette {
    margin: 2px auto;
    padding: 5px 0;
    height: 100px;
  }
}
.layout-roulettes_more .roulette__items {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.layout-roulettes_more .roulette-item img {
  height: 60px;
  object-fit: contain;
  object-position: 50% 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.layout-roulettes_more .roulette-item__type,
.layout-roulettes_more .roulette-item__name {
  display: none;
}
.layout-roulettes_10 .layout-roulette {
  padding: 0;
}
.layout-roulettes_10 .roulette {
  -webkit-transform: translate3d(0, 0, 0);
}
@media not screen and (min-width: 630px) {
  .layout-roulettes_10 .roulette {
    margin: 0 auto;
    margin-bottom: 7px;
    padding: 0;
    height: 64px;
  }
}
.layout-roulettes_10 .roulette__items {
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.layout-roulettes_10 .roulette-item {
  width: 90px;
}
.layout-roulettes_10 .roulette-item img {
  height: 35px;
  object-fit: contain;
  object-position: 50% 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.layout-roulettes_10 .roulette-item__type,
.layout-roulettes_10 .roulette-item__name {
  display: none;
}
.farmcase-drops {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.farmcase-drops__drop {
  flex: none;
}
.farmcase-drops {
  padding: 5px;
  position: relative;
  text-align: center;
}
.farmcase-drops__card {
  position: relative;
  width: 67px;
  height: 55px;
  padding: 3px;
  margin: 2px;
  font-size: 6px;
}
.farmcase-drops__card--10 {
  width: 130px;
  height: 95px;
  font-size: 10px;
}
.farmcase-drops__card--25 {
  width: 95px;
  height: 65px;
  font-size: 8px;
}
.farmcase-drops.farmcase-drops-10 .farmcase-drop.farmcase-drop-winner .farmcase-drop-flipper {
  transition: all 1s linear;
}
.farmcase-drops.farmcase-drops-10 .farmcase-drop.farmcase-drop-winner.opened:before {
  animation: scaleopacity 1s 3s 1 ease-in-out forwards;
}
.farmcase-drops.farmcase-drops-10 .farmcase-drop-flipper {
  transition: all 0.5s linear;
}
.farmcase-drop {
  display: inline-block;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.farmcase-drop.opened .farmcase-drop-flipper {
  transform: rotateY(180deg);
}
.farmcase-drop.opened .farmcase-drop-front {
  border-color: transparent;
}
.farmcase-drop.farmcase-drop-winner {
  z-index: 5;
}
.farmcase-drop.farmcase-drop-winner .farmcase-drop-flipper {
  transition: all 2s linear;
}
.farmcase-drop.farmcase-drop-winner.opened:before {
  display: block;
  animation: scaleopacity 1s 2s 1 ease-in-out forwards;
  will-change: opacity;
}
.farmcase-drop-flipper {
  position: relative;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: all 0.5s linear;
  transform: rotateY(0deg);
  will-change: transform;
  width: 100%;
  height: 100%;
}
@keyframes scaleopacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.farmcase-drop-front,
.farmcase-drop-back {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 3;
}
.farmcase-drop-front {
  background: transparent url('../../assets/img/farm/farm_itemback.png') no-repeat;
  background-size: 100%;
  border: 1px solid #639B97;
  border-radius: 3px;
}
.farmcase-drop-back {
  transform: rotateY(180deg);
}
.item-incase {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: 0.15s ease;
}
.item-incase__img {
  width: 60%;
  height: auto;
  max-height: 60%;
  margin-bottom: 2px;
  padding-top: 2px;
}
.item-incase__names {
  width: 100%;
  padding: 0 5px;
  font-size: inherit;
  position: relative;
  z-index: 2;
  text-align: left;
}
.item-incase__first-name,
.item-incase__second-name {
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.item-incase_common {
  background-image: url('../assets/img/item-frames/item_overlay_common.svg');
}
.item-incase_uncommon {
  background-image: url('../assets/img/item-frames/item_overlay_uncommon.svg');
}
.item-incase_milspec {
  background-image: url('../assets/img/item-frames/item_overlay_milspec.svg');
}
.item-incase_restricted {
  background-image: url('../assets/img/item-frames/item_overlay_restricted.svg');
}
.item-incase_classified {
  background-image: url('../assets/img/item-frames/item_overlay_classified.svg');
}
.item-incase_covert {
  background-image: url('../assets/img/item-frames/item_overlay_covert.svg');
}
.item-incase_rare {
  background-image: url('../assets/img/item-frames/item_overlay_rare.svg');
}
.promocode-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
@media (max-width: 913px) {
  .promocode-form {
    padding: 0 20px;
  }
}
.promocode-form__label {
  margin-bottom: 10px;
  font-weight: 700;
  line-height: 120%;
  color: white;
  font-size: 22px;
  cursor: pointer;
  padding-left: 10px;
}
.promocode-form__label a {
  color: #FBCA02;
  transition: 0.2s ease;
}
.promocode-form__label a:hover {
  color: #F3E603;
}
.promocode-form__form {
  display: flex;
  align-items: center;
  padding: 4px;
  max-width: 885px;
  width: 100%;
  border-radius: 7px;
  background-color: rgba(54, 47, 47, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.3);
  transition: 0.2s ease;
  padding-left: 25px;
  position: relative;
}
@media (max-width: 600px) {
  .promocode-form__form {
    flex-direction: column;
    padding-left: 4px;
  }
}
.promocode-form__form.active {
  border-color: #FBCA02;
  box-shadow: 0 0 15px 0 rgba(251, 202, 2, 0.3);
}
.promocode-form__input {
  width: 100%;
  height: 100%;
}
.promocode-form__input input {
  height: 100%;
  padding: 5px 0;
  width: 100%;
  background-color: transparent;
  font-family: 'PT Sans Narrow', sans-serif;
  color: #fff;
  height: 48px;
  font-size: 26px;
}
@media (max-width: 600px) {
  .promocode-form__input input {
    padding-left: 10px;
  }
}
.promocode-form__btn {
  flex: none;
  width: auto;
}
@media (max-width: 600px) {
  .promocode-form__btn {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -100%;
  }
  .promocode-form__btn .button {
    padding: 10px 24px;
    width: 100%;
  }
}
.promocode-form__errors {
  color: red;
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  bottom: calc(-170%);
}
.promocode-form__success {
  color: #1cd025;
  width: 100%;
  position: absolute;
  left: 0;
  text-align: center;
  bottom: calc(-170%);
}
.preloader {
  position: fixed;
  margin: auto;
  display: block;
  width: 150px;
  height: 150px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (min-width: 1100px) {
  .preloader {
    width: 200px;
    height: 200px;
  }
}
.preloader img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
}
@media (min-width: 1100px) {
  .preloader img {
    width: 100px;
    height: 100px;
  }
}
.preloader-whirlpool,
.preloader-whirlpool::after {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 3px solid #7f8e94;
  border-left-color: #ffd000;
  border-radius: 2747px;
  box-sizing: content-box;
}
.preloader-whirlpool {
  margin: -69px 0 0 -69px;
  height: 138px;
  width: 138px;
  animation: preloader-rotate 1000ms linear infinite;
}
.preloader-whirlpool::after {
  content: "";
  margin: -80px 0 0 -80px;
  height: 154px;
  width: 154px;
  animation: preloader-rotate 2000ms linear infinite;
}
@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}
.error {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}
.error__icon {
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
}
.error__title {
  font-size: 19px;
  color: white;
}
