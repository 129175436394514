.farmcase-drops {
	padding: 5px;
	position: relative;
	text-align: center;
	&__card {
		position: relative;
		width: 67px;
		height: 55px;
		padding: 3px;
		margin: 2px;
		font-size: 6px;
		&--10 {
			width: 130px;
			height: 95px;
			font-size: 10px;
		}
		&--25 {
			width: 95px;
			height: 65px;
			font-size: 8px;
		}
	}
	&.farmcase-drops-10 {
		.farmcase-drop {
			&.farmcase-drop-winner {
				.farmcase-drop-flipper {
					transition: all 1s linear;
				}
				&.opened {
					&:before {
						animation: scaleopacity 1s 3s 1 ease-in-out forwards;
					}
				}
			}
		}
		.farmcase-drop-flipper {
			transition: all .5s linear;
		}
	}
}
.farmcase-drop {
	display: inline-block;
	-webkit-perspective: 1000;
	-moz-perspective: 1000;
	-o-perspective: 1000;
	perspective: 1000;
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 1;
	&.opened {
		.farmcase-drop-flipper {
			transform: rotateY(180deg);
		}
		.farmcase-drop-front{
			border-color: transparent;
		}
	}
	&.farmcase-drop-winner {
		z-index: 5;
		.farmcase-drop-flipper {
			transition: all 2s linear;
		}
		&.opened {
			&:before {
				display: block;
				animation: scaleopacity 1s 2s 1 ease-in-out forwards;
				will-change: opacity;
			}
		}
	}
}
.farmcase-drop-flipper {
	position: relative;
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	-o-transform-style: preserve-3d;
	transform-style: preserve-3d;
	transition: all .5s linear;
	transform: rotateY(0deg);
	will-change: transform;
	width: 100%;
	height: 100%;
}
@keyframes scaleopacity {
	0% {opacity: 0;}
	100% {opacity: 1;}
}
.farmcase-drop-front, .farmcase-drop-back {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	z-index: 3;
}
.farmcase-drop-front {
	background: transparent url('../../../assets/img/farm/farm_itemback.png') no-repeat;
	background-size: 100%;
	border: 1px solid #639B97;
	border-radius: 3px;
}

.farmcase-drop-back {
	transform: rotateY(180deg);
}