.promocode-form{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	@media(max-width: 913px){
		padding: 0 20px;
	}
	&__label{
		margin-bottom: 10px;
		font-weight: 700;
		line-height: 120%;
		color: white;
		font-size: 22px;
		cursor: pointer;
		padding-left: 10px;
		a{
			color: @color-main;
			transition: .2s ease;
			&:hover{
				color: @color-main-2;
			}
		}
	}
	&__form{
		display: flex;
		align-items: center;
		padding: 4px;
		max-width: 885px;
		width: 100%;
		border-radius: 7px;
		background-color: fade(#362F2F, 30%);
		border: 1px solid fade(#fff, 30%);
		transition: .2s ease;
		padding-left: 25px;
		position: relative;
		@media(max-width: 600px){
			// padding-left: 10px;
			flex-direction: column;
			padding-left: 4px;
		}
		&.active{
			border-color: @color-main;
			box-shadow: 0 0 15px 0 fade(@color-main, 30%);
		}
	}
	&__input{
		width: 100%;
		height: 100%;
		input {
			height: 100%;
			padding: 5px 0;
			width: 100%;
			background-color: transparent;
			font-family: 'PT Sans Narrow', sans-serif;
			color: #fff;
			height: 48px;
			font-size: 26px;
			@media(max-width: 600px) {
				padding-left: 10px;
			}
		}
	}
	&__btn{
		flex: none;
		width: auto;
		@media(max-width: 600px){
			width: 100%;
			position: absolute;
			left: 0;
			bottom: -100%;
			.button {
				padding: 10px 24px;
				// background-image: url(/img/icon_check_black.svg);
				// background-repeat: no-repeat;
				// background-position: center;
				// font-size: 0;
				width: 100%;
			}
		}
	}
	&__errors {
		color: red;
		width: 100%;
		position: absolute;
		left: 0;
		text-align: center;
		bottom: calc(-170%);
	}
	&__success {
		color: rgb(28, 208, 37);
		width: 100%;
		position: absolute;
		left: 0;
		text-align: center;
		bottom: calc(-170%);
	}
}