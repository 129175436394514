.main-page {
    height: 100vh;
    &__header {
        // background-color: #0a0d1d;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
        color: white;
        text-align: center;
    }
}