.layout-roulettes {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    &_three {
        .layout-roulette {
            padding: 0;
        }
    }
    &_more {
        .layout-roulette {
            padding: 0;
        }
        .roulette {
            -webkit-transform:translate3d(0,0,0);
            .max(@small, {
                margin: 2px auto;
                padding: 5px 0;
                height: 100px;
            });
            &__items {
                will-change: transform;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
        }
        .roulette-item {
            img {
                height: 60px;
                object-fit: contain;
                object-position: 50% 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &__type, &__name{
                display: none;
            }
        }
    }
    &_10 {
        .layout-roulette {
            padding: 0;
        }
        .roulette {
            -webkit-transform:translate3d(0,0,0);
            .max(@small, {
                margin: 0 auto;
                margin-bottom: 7px;
                padding: 0;
                height: 64px;
            });
            &__items {
                will-change: transform;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
        }
        .roulette-item {
            width: 90px;
            img {
                height: 35px;
                object-fit: contain;
                object-position: 50% 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
            &__type, &__name{
                display: none;
            }
        }
    }
    
}