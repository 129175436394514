.button{
	.column-acenter();
	justify-content: center;
	width: 100%;
	padding: 15px 20px;
	background-color: @color-main;
	border-radius: 5px;
	.font(16px; 24px; 700; @color-text);
	white-space: nowrap;
	text-transform: uppercase;
	cursor: pointer;
	transition: .2s ease;
	text-align: center;
	border: 2px solid transparent;
	@media(min-width: @small){
		padding: 15px 40px;
		font-size: 19px;
	}
	&__value{
		display: flex;
	}
	&:hover{
		background-color: @color-main-2;
	}
	&_refill{
		padding: 10px 25px;
		font-size: 30px;
		line-height: 35px;
		span{
			&:last-child{
				margin-top: 5px;
				font-size: 10px;
				line-height: 13px;
			}
		}
	}
	&_make-contract{
		font-size: 15px;
		span{
			&:last-child{
				font-size: 13px;
			}
		}
	}
	&_main{
		padding: 10px 30px;
		font-size: 18px;
	}
	&_preload{
		padding: 10px 50px;
		min-height: 45px;
		width: auto;
		@media(min-width: @small){
			min-height: 60px;
		}
	}
	&_compact{
		padding: 15px 20px;
	}
	&_red{
		background-color: @color-main-red;
		color: #fff;
		&:hover{
			background-color: lighten(@color-main-red, 7%);
		}
	}
	&_lowercase{
		text-transform: none;
		padding: 15px 25px;
		font-size: 14px;
		line-height: 18px;
	}
	&:disabled{
		background-color: fade(#d6ca9c, 90%);
		filter: grayscale(1);
		cursor: default;
	}

	&_ghost{
		border: 2px solid #fbca02;
		background-color: rgba(251, 202, 2, 0.25);
		color: #fff;
		&:hover{
			background-color: rgba(251, 202, 2, 0.50);
		}
	}

	&_transparent{
		background: transparent;
	}

	&_transparent&_ghost{
		color: #FBCA02;
	}

	&_with-value{
		flex-direction: row;
		justify-content: space-between;
	}
	&_with-value&_ghost &{
		&__label{
			color: #F5B904;
		}
	}
	&_super-small{
		font-size: 12px;
		padding: 7px 13px;
	}

	&_small{
		padding: 16px 19px;
		font-size: 14px;
	}

	&_mobile-square{
		border-radius: 0;
		@media(min-width: @small){
			border-radius: 5px;
		}
	}
	&_rounded{
		border-radius: 55px;
	}
	&_border {
		background-color: transparent;
		color: @color-main;
		border: 1px solid rgba(251, 202, 2, 0.3);
		padding: 17px 20px;
		font-size: 14px;
		line-height: 16px;
		width: fit-content;
		&:hover {
			color: @color-text;
		}
		&:disabled {
			opacity: 0.3;
			pointer-events: none;
		}
	}
	&_fit {
		width: fit-content;
	}
}