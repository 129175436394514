.max(@max; @styles){
	@media not screen and (min-width: @max){
		@styles();
	}
}
.min(@min; @styles){
	@media only screen and (min-width: @min){
		@styles();
	}
}
.only(@min; @max; @styles){
	@media only screen and (min-width: @min) and (max-width: (@max - 1)){
		@styles();
	}
}

.bg(@w; @h; @url; @bgsize: 100% 100%; @repeat: no-repeat; @position: center) {
	width: @w;
	height: @h;
	background-size: @bgsize;
	background-repeat: @repeat;
	background-image: url(@url);
	background-position: @position;
}

.text-overflow() {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.font(@size; @lh; @weight: normal; @color: #fff){
	font-size: @size;
	line-height: @lh;
	font-weight: @weight;
	color: @color;
}

.column-acenter(){
	display: flex;
	flex-direction: column;
	align-items: center;
}

.hover(@styles) {
	@media not all and (hover: none) and (pointer: coarse) {
		&:hover {
			@styles();
		}
	}

	@media all and (hover: none) and (pointer: coarse) {
		&:active {
			-webkit-tap-highlight-color: transparent;
			@styles();
		}
	}
}

.posc(){
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}