//Media queries
@small: 630px;
@medium: 1100px;
@large: 1470px;
@huge: 1720px;
@tv: 2300px;

//easy-drop
@color-site: #06151A;
@color-cyan: #065D55;
@color-light-site: #0A222B;
@color-text: #07111A;
@color-main: #FBCA02;
@color-main-2: #F3E603;
@color-text-dark: #000;
@green: #82FF1B;
@color-main-red: #D0021B;
@text-second: #B1BBCA;
@blue: #109cf6;
//Button color
@dark-color: #2C3646;
@btn-color-noty: #FF9A30;

//Base colors
@slate50: #070A18;
@slate100: #0A0D1D;
@slate150: #0F1223;
@slate200: #1E2339;
@slate250: #41496B;
@slate300: #767B9F;
@slate320: #8F96B7;
@slate350: #B7BCDB;

//Accent colors
@green: #82FF1B;
@green500: #58A533;

@green50: #93FF1F;

@orange50: #FF552E;
@orange100: #FF7100;
@orange500: #FF9B01;

@orange200: #FF8038;

@red50: #FF3030;

@yellow50: #FFC700;

@cyan50: #13CDFF;

//Rarity colors
@common: #89c0db;
@uncommon: #009ae2;
@milspec: #1E78FF;
@restricted: #8F00FF;
@classified: #FF12AE;
@covert: #FF1212;
@rare: #FFC701;

//Раритетные классы
@rcommon: common;
@runcommon: uncommon;
@rmilspec: milspec;
@rrestricted: restricted;
@rclassified: classified;
@rcovert: covert;
@rrare: rare;

@rarity-names: @rcommon @runcommon @rmilspec @rrestricted @rclassified @rcovert @rrare;
@rarity-colors: @common @runcommon @milspec @restricted @classified @covert @rare;

//CSS vars
.accent{
	--rarity-color: @cyan50;
	--state-color: @cyan50;
}
.error{
	--rarity-color: @red50;
	--state-color: @red50;
}
.success{
	--rarity-color: @green;
	--state-color: @green;
}

.default{
	--rarity-color: @slate250;
	--rarity-color-0: fade(@slate250, 0%);
	--rarity-color-10: fade(@slate250, 10%);
	--rarity-color-35: fade(@slate250, 35%);
	--rarity-color-70: fade(@slate250, 70%);
	--rarity-color-90: fade(@slate250, 90%);
}

.uncommon{
	--rarity-color: @uncommon;
	--rarity-color-lighten: #9FAEC5;
	--rarity-color-0: fade(@uncommon, 0%);
	--rarity-color-10: fade(@uncommon, 10%);
	--rarity-color-35: fade(@uncommon, 35%);
	--rarity-color-70: fade(@uncommon, 70%);
	--rarity-color-90: fade(@uncommon, 90%);
}

.common{
	--rarity-color: @common;
	--rarity-color-lighten: #9FAEC5;
	--rarity-color-0: fade(@common, 0%);
	--rarity-color-10: fade(@common, 10%);
	--rarity-color-35: fade(@common, 35%);
	--rarity-color-70: fade(@common, 70%);
	--rarity-color-90: fade(@common, 90%);
}

.milspec{
	--rarity-color: @milspec;
	--rarity-color-lighten: #9FAEC5;
	--rarity-color-0: fade(@milspec, 0%);
	--rarity-color-10: fade(@milspec, 10%);
	--rarity-color-35: fade(@milspec, 35%);
	--rarity-color-70: fade(@milspec, 70%);
	--rarity-color-90: fade(@milspec, 90%);
}

.restricted{
	--rarity-color: @restricted;
	--rarity-color-lighten: #B59BCD;
	--rarity-color-0: fade(@restricted, 0%);
	--rarity-color-10: fade(@restricted, 10%);
	--rarity-color-35: fade(@restricted, 35%);
	--rarity-color-70: fade(@restricted, 70%);
	--rarity-color-90: fade(@restricted, 90%);
}

.classified{
	--rarity-color: @classified;
	--rarity-color-lighten: #BD9FB7;
	--rarity-color-0: fade(@classified, 0%);
	--rarity-color-10: fade(@classified, 10%);
	--rarity-color-35: fade(@classified, 35%);
	--rarity-color-70: fade(@classified, 70%);
	--rarity-color-90: fade(@classified, 90%);
}

.covert{
	--rarity-color: @covert;
	--rarity-color-lighten: #BF9EA2;
	--rarity-color-0: fade(@covert, 0%);
	--rarity-color-10: fade(@covert, 10%);
	--rarity-color-35: fade(@covert, 35%);
	--rarity-color-70: fade(@covert, 70%);
	--rarity-color-90: fade(@covert, 90%);
}

.rare{
	--rarity-color: @rare;
	--rarity-color-lighten: #BFB8A0;
	--rarity-color-0: fade(@rare, 0%);
	--rarity-color-10: fade(@rare, 10%);
	--rarity-color-35: fade(@rare, 35%);
	--rarity-color-70: fade(@rare, 70%);
	--rarity-color-90: fade(@rare, 90%);
}